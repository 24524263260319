.checkbox {
    border-radius: 50%;
    box-sizing: initial;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
    height: 24px;
    overflow: hidden;
    padding: 8px;
    position: relative;
    width: 24px;
}

.checkbox:hover {
    background-color: rgba(65, 117, 5, 0.08);
}

.checkboxSelected {
    color: rgb(65, 117, 5);
}

.checkboxInput {
    cursor: inherit;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.checkboxDisabled {
    color: rgba(0, 0, 0, 0.26);
}

.checkboxDisabled.checkbox:hover {
    background-color: transparent;
}

.checkboxDisabled .checkboxSelected {
    color: rgba(0, 0, 0, 0.26);
}

.checkboxDisabled .checkboxInput {
    cursor: default;
}
